import React, {useState} from 'react';
import { crashCourseData } from '../sitedata';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function CrashCourse() {

    const [selectedCourse, setSelectedCourse] = useState({details:[]});

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = (event, val) => {
        event.preventDefault();
        //console.log("Val : ", val);
        setSelectedCourse(val);
        setShow(true);
    }
  

  return (
    <div >

    <Offcanvas style={{paddingTop:"80px"}} show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{selectedCourse.courseName}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="course-card">

        <figure className="card-banner img-holder" style={{width: 270, height: 120, alignContent:'center'}}>
        <img src={selectedCourse.cover} width="270" height="120" loading="lazy"
            alt="Build Responsive Real- World Websites with HTML and CSS" className="img-cover"/>
        </figure>
        <div className="card-content">
        <span className="badge">{selectedCourse.levelTitle}</span>
        <h3 className="h3">
            <a href="#" className="card-title">{selectedCourse.courseName}</a>
        </h3>
        <data className="price" value="29">Duration : {selectedCourse.duration}</data>
        <ul className="card-meta-list">
        {selectedCourse.details.map((item, index)=>
            <li className="card-meta-list" key={index}>
            <ion-icon name="checkbox-outline"></ion-icon>
            <span className="span">{item.details}</span>
            </li>)}
        </ul>
        <a href="#contact" className="btn has-before" onClick={handleClose}>
        <span className="span">Enroll Now!</span>
        <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
        </a>
        </div>
        </div>
        </Offcanvas.Body>
      </Offcanvas>
       
        <section className="section course" id="courses" aria-label="course">
        <div className="container">
          <p className="section-subtitle">Popular Crash Courses</p>
          <h2 className="h2 section-title">Pick A Course To Get Started</h2>
          <ul className="grid-list">
            {crashCourseData.map((val, index) => (
            <li key={index}>
              <div className="course-card">
                <figure className="card-banner img-holder" style={{width: 370, height: 220}}>
                  <img src={val.cover} width="370" height="220" loading="lazy"
                    alt="Build Responsive Real- World Websites with HTML and CSS" className="img-cover"/>
                </figure>
                <div className="abs-badge">
                  <ion-icon name="time-outline" aria-hidden="true"></ion-icon>

                  <span className="span">{val.duration}</span>
                </div>
                <div className="card-content">
                  <h3 className="h3">
                    <a href="#" className="card-title">{val.courseName}</a>
                  </h3>
                  <p class="card-text">{val.desc}
                  </p>
        <button className="btn has-before" onClick={event=>handleShow(event, val)}>
            <span className="span">Know More</span>
            <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
          </button>
                </div>
              </div>
              </li>
))}
          </ul>
        </div>
        </section>
    </div>
  )
}
