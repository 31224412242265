import React, { useState } from 'react';
import { coursesCard } from '../sitedata';
import Offcanvas from 'react-bootstrap/Offcanvas';

export default function DegreeCourse() {
    const [selectedCourse, setSelectedCourse] = useState({levelDetails:[]});

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = (val) => {
        //event.preventDefault();
        //console.log("Val : ", val);
        setSelectedCourse(val);
        setShow(true);
    }
  

  return (
    <div >
        <Offcanvas style={{paddingTop:"80px"}} show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{selectedCourse.courseName}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="course-card">
        <figure className="card-banner img-holder" style={{width: 270, height: 120, alignContent:'center'}}>
        <img src={selectedCourse.cover} width="270" height="120" loading="lazy"
            alt="Build Responsive Real- World Websites with HTML and CSS" className="img-cover"/>
        </figure>
        <div className="card-content">
        <span className="badge">{selectedCourse.levelTitle}</span>
        <h3 className="h3">
            <a href="#" className="card-title">{selectedCourse.coursesName}</a>
        </h3>
        <data className="price" value="29">Levels : {selectedCourse.levels}</data>
        {selectedCourse.levelDetails.map((item, index)=>
        <ul className="card-meta-list " key={index}>
            <h3>{item.level}</h3>
            {item.details.map((items, index)=>
            <li className="card-meta-list about-item " key={index}>
                <ion-icon name="checkmark-done-outline" aria-hidden="true"></ion-icon>
                <span className="span">{items.item}</span>
           
            </li>)}
            {/*<li className="card-meta-item">
            <ion-icon name="people-outline" aria-hidden="true"></ion-icon>
            <span className="span">20 Students</span>
            </li>*/}
        </ul>)}
        <a href="#contact" className="btn has-before" onClick={handleClose}>
        <span className="span">Enroll Now!</span>
        <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
        </a>
        </div>
        </div>
        </Offcanvas.Body>
      </Offcanvas>


      <section className="section blog has-bg-image" id="blog" aria-label="blog"
        style={{backgroundImage: `url('./images/blog-bg.svg')`}}>
        <div className="container">
                      <p className="section-subtitle">Popular Certificate Courses</p>
          <h2 className="h2 section-title">Get a <span className="span">Certificate / Degree</span> from University</h2>
         <p> Basic to advance level knowledge and hands on practice on all the mediums like – pencil sketching, pencil shading, charcoal shading, pastels, water colour, oil colour, acrylic colour, mixed medium, wall painting, fabric painting, glass painting, different types of craft works and many more…</p>


          <ul className="grid-list">
          {coursesCard.map((val, index) => (

            <li key={index}>
              <div className="blog-card">

                <figure className="card-banner img-holder has-after" style={{width: 370, height: 370}}>
                  <img src={val.cover} width="370" height="370" loading="lazy"
                    alt="Become A Better Blogger: Content Planning" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <a className="card-btn" aria-label="read more" onClick={()=>handleShow(val)}>
                    <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                  </a>

                  {/*<a href="#" className="card-subtitle">{val.levelTitle}</a>*/}

                  <h3 className="h3">
                    <a href="#" className="card-title">{val.coursesName}</a>
                  </h3>

                  <ul className="card-meta-list">
                  {val.levelDetails.map((item, index)=>

                    <li className="card-meta-item" key={index}>
                    <ion-icon name="library-outline" aria-hidden="true"></ion-icon>

                    <span className="span">{item.level}</span>
                    </li>
                    )}


                   {/*} <li className="card-meta-item">
                      <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>

                      <span className="span">Oct 10, 2021</span>
                    </li>

                    <li className="card-meta-item">
                      <ion-icon name="chatbubbles-outline" aria-hidden="true"></ion-icon>

                      <span className="span">Com 09</span>
                  </li>*/}

                  </ul>

                  <p className="card-text">
                    {val.desc}
                  </p>

                </div>

              </div>
            </li>
          ))}

           {/*} <li>
              <div className="blog-card">

                <figure className="card-banner img-holder has-after" style={{width: 370, height: 370}}>
                  <img src="./images/blog-2.jpg" width="370" height="370" loading="lazy"
                    alt="Become A Better Blogger: Content Planning" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <a href="#" className="card-btn" aria-label="read more">
                    <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                  </a>

                  <a href="#" className="card-subtitle">Online</a>

                  <h3 className="h3">
                    <a href="#" className="card-title">Become A Better Blogger: Content Planning</a>
                  </h3>

                  <ul className="card-meta-list">

                    <li className="card-meta-item">
                      <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>

                      <span className="span">Oct 10, 2021</span>
                    </li>

                    <li className="card-meta-item">
                      <ion-icon name="chatbubbles-outline" aria-hidden="true"></ion-icon>

                      <span className="span">Com 09</span>
                    </li>

                  </ul>

                  <p className="card-text">
                    Lorem Ipsum Dolor Sit Amet Cons Tetur Adipisicing Sed.
                  </p>

                </div>

              </div>
            </li>

            <li>
              <div className="blog-card">

                <figure className="card-banner img-holder has-after" style={{width: 370, height: 370}}>
                  <img src="./images/blog-3.jpg" width="370" height="370" loading="lazy"
                    alt="Become A Better Blogger: Content Planning" className="img-cover"/>
                </figure>

                <div className="card-content">

                  <a href="#" className="card-btn" aria-label="read more">
                    <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                  </a>

                  <a href="#" className="card-subtitle">Online</a>

                  <h3 className="h3">
                    <a href="#" className="card-title">Become A Better Blogger: Content Planning</a>
                  </h3>

                  <ul className="card-meta-list">

                    <li className="card-meta-item">
                      <ion-icon name="calendar-outline" aria-hidden="true"></ion-icon>

                      <span className="span">Oct 10, 2021</span>
                    </li>

                    <li className="card-meta-item">
                      <ion-icon name="chatbubbles-outline" aria-hidden="true"></ion-icon>

                      <span className="span">Com 09</span>
                    </li>

                  </ul>

                  <p className="card-text">
                    Lorem Ipsum Dolor Sit Amet Cons Tetur Adipisicing Sed.
                  </p>

                </div>

              </div>
          </li>*/}

          </ul>

          <img src="./images/blog-shape.png" width="186" height="186" loading="lazy" alt=""
            className="shape blog-shape"/>

        </div>
      </section>


            </div>
  )
}
