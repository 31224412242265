import React from 'react'

export default function Stats() {
  return (
    <div>
<section className="section stats" aria-label="stats">
        <div className="container">

          <ul className="grid-list">

            <li>
              <div className="stats-card" style={{backgroundColor: "hsla(170, 75%, 41%,0.1)"}}>
                <h3 className="card-title">29.3k</h3>

                <p className="card-text">Student Enrolled</p>
              </div>
            </li>

            <li>
              <div className="stats-card" style={{backgroundColor: "hsla(351, 83%, 61%,0.1)"}}>
                <h3 className="card-title">32.4K</h3>

                <p className="card-text">Class Completed</p>
              </div>
            </li>

            <li>
              <div className="stats-card" style={{backgroundColor: "hsla(260, 100%, 67%,0.1)"}}>
                <h3 className="card-title">100%</h3>

                <p className="card-text">Satisfaction Rate</p>
              </div>
            </li>

            <li>
              <div className="stats-card" style={{backgroundColor: "hsla(42, 94%, 55%,0.1)"}}>
                <h3 className="card-title">4</h3>

                <p className="card-text">Countries</p>
              </div>
            </li>

          </ul>

        </div>
      </section>

    </div>
  )
}
