import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
  } from 'reactstrap';

  import { videoData } from '../sitedata';
  
  const items = [
    {
      src: 'https://picsum.photos/id/123/1200/400',
      altText: 'Slide 1',
      caption: 'Slide 1',
      key: 1,
    },
    {
      src: 'https://picsum.photos/id/456/1200/400',
      altText: 'Slide 2',
      caption: 'Slide 2',
      key: 2,
    },
    {
      src: 'https://picsum.photos/id/678/1200/400',
      altText: 'Slide 3',
      caption: 'Slide 3',
      key: 3,
    },
  ];

export default function ArtVideo(args) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
  

    const [playVideo, setPlayVideo]=useState(false);

    const handlePlayVideo=(event)=>{
        event.preventDefault();
        console.log("Test");
        setPlayVideo(true);
    }

    const handleStopPlay=()=>{
        setPlayVideo(false);
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
      };
    
      const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
      };
    
      const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
      };
    
      const slides = videoData.map((item) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
            {!playVideo?<div className="video-banner img-holder has-after">
              {/*<img src="./images/video-banner.jpg" width="970" height="550" loading="lazy" alt="video banner"
                className="img-cover"/>*/}
              <ReactPlayer  url={item.sourceURL} width="850px" height="550px" playing={playVideo} onEnded={handleStopPlay} />
              <button className="play-btn" aria-label="play video" onClick={event=>handlePlayVideo(event)}>
                <ion-icon name="play" aria-hidden="true"></ion-icon>
              </button>
            </div>:
            <div className="video-banner img-holder has-after">
                <ReactPlayer url={item.sourceURL}  playing={playVideo} onEnded={handleStopPlay} />
            </div>}
            <CarouselCaption
              captionText={item.caption}
              captionHeader={item.caption}
            />
          </CarouselItem>
        );
      });

  return (
    <div>
    <section className="video has-bg-image" aria-label="video"
        style={{backgroundImage: "url('./images/video-bg.png')"}}>
        <div className="container">
        <h2 className="h2 section-subTitle text-center">Our Art Videos</h2>

          <div className="video-card">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            //onSelect={console.log("test")}
            {...args}
            >
            <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
          </Carousel>
        <img src="./images/video-shape-1.png" width="1089px" height="605px" alt=""
              className="shape video-shape-1"/>
        <img src="./images/video-shape-2.png" width="158px" height="174px" alt=""
              className="shape video-shape-2"/>
        </div>
        </div>
    </section>
    </div>
  )
}
