/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import "./Header.css";
import { CSSTransition } from "react-transition-group";

export default function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <main>

    <header className="Header" id="home" aria-label="home"
      style={{backgroundImage: `url("./images/banner/navbar-bg2.jpeg")`}}>
    <span className="HeaderTitle">
    <div style={{display:"flex", justifyContent:"left", paddingLeft:"5%"}}>
    <img src="./images/logo.png" className="logo" width="60px" height="20px" style={{borderRadius:20}} alt="EduWeb logo" />
    <h3 className="title" style={{ paddingLeft:"6px",  color:"white", fontFamily:"Satisfy"}}>
    The Creative World Academy
    </h3>   
    </div>           
    </span>
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <a href="#home" onClick={toggleNav}>Home</a>
          <a href="#courses" onClick={toggleNav}>Courses</a>
          <a href="#about" onClick={toggleNav}>About</a>
          <a href="#gallery" onClick={toggleNav}>Gallery</a>
          <a href="#contact" onClick={toggleNav}>Contact</a>
        </nav>
      </CSSTransition>
      <button onClick={toggleNav} className="Burger" style={{color:"white"}}>
      <ion-icon  name="menu-outline" aria-hidden="true"></ion-icon>
      </button>
    </header>
    </main>

  );
}
