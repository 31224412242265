import React from 'react'

export default function Hero() {
  return (
    <div>

    <section className="section category" aria-label="category">
        <div className="container">
         {/*} <p className="section-subtitle">Categories</p>*/}
          <h2 className="h2 section-title">
            Unique <span className="span">Benefits</span> of Creative World Academy.
          </h2>
          <p className="h3 section-title">
            Add Colours to your Creativity!!!
          </p>
          <ul className="grid-list">
            <li>
              <div className="category-card" style={{backgroundColor: "hsla(170, 75%, 41%,0.1)"}}>
                <div className="card-icon">
                  <img src="./images/category-1.svg" width="40" height="40" loading="lazy"
                    alt="Online Degree Programs" className="img" />
                </div>
                <h3 className="h3">
                  <a href="#" className="card-title">Online & Offline Programs</a>
                </h3>

                <p className="card-text">
                  The Creative World Academy offers both Offline and Online programs.
                </p>
                <span className="card-badge">7 Courses</span>
              </div>
            </li>
            <li>
              <div className="category-card" style={{backgroundColor: "hsla(351, 83%, 61%,0.1)"}}>
                <div className="card-icon">
                  <img src="./images/category-2.svg" width="40" height="40" loading="lazy"
                    alt="Non-Degree Programs" className="img" />
                </div>
                <h3 className="h3">
                  <a href="#" className="card-title">Degree Programs</a>
                </h3>
                <p className="card-text">
                The Creative World Academy offers crash courses with Certificates and degree programs.
                </p>
                <span className="card-badge">4 Courses</span>
              </div>
            </li>
            <li>
              <div className="category-card" style={{backgroundColor: `hsla(229, 75%, 58%,0.1)`}}>
                <div className="card-icon">
                  <img src="./images/category-3.svg" width="40" height="40" loading="lazy"
                    alt="Off-Campus Programs" className="img" />
                </div>
                <h3 className="h3">
                  <a href="#" className="card-title">University Collaboration</a>
                </h3>
                <p className="card-text">
                  Only institution in South India to offer degree programs with University Collaboration.
                </p>
                <span className="card-badge">8 Courses</span>
              </div>
            </li>
            <li>
              <div className="category-card" style={{backgroundColor: "hsla(42, 94%, 55%,0.1)"}}>
                <div className="card-icon">
                  <img src="./images/category-4.svg" width="40" height="40" loading="lazy"
                    alt="Hybrid Distance Programs" className="img" />
                </div>
                <h3 className="h3">
                  <a href="#" className="card-title">Four Countries</a>
                </h3>
                <p className="card-text">
                Our Academy offers training both Offline and Online classes in 4 different countries. 
                </p>
                <span className="card-badge">8 Courses</span>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}
