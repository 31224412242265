import React, {useState} from 'react';
import NavBar from './NavBar';
import FrontPage from './FrontPage';
import Hero from './Hero';
import Course from './Course';
import About from './About';
import Stats from './Stats';
import Footer from './Footer';
import AboutUs from './AboutUs';
import CrashCourse from './CrashCourse';
import FAQ from './FAQ';
import Gallery from './Gallery';
import ArtVideo from './ArtVideo';
import ContactUs from './ContactUs';
import Countries from './Countries';
import '../styles/style.css';
import DegreeCourse from './DegreeCourse';

const Header1 = () => {
    const [isScrolled, setIsScrolled] = useState(false);
  
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return (
      <header className={`header ${isScrolled ? 'active' : ''}`}>
        {/* Header content */}
      </header>
    );
  };
  
  const BackTopButton = () => {
    const [isScrolled, setIsScrolled] = useState(false);
  
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
  
    const handleTopMove=()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  
    window.addEventListener('scroll', handleScroll);
  
    return (
      <button className={`back-top-btn ${isScrolled ? 'active' : ''}`} onClick={()=>handleTopMove()}>
      <ion-icon name="chevron-up" aria-hidden="true"></ion-icon>
      </button>
    );
  };
  
  
  

export default function HomePage() {
    return (
    <div>
        <div id="top">
            <FrontPage />
            <Hero />
            <AboutUs />
            <About />
            <Countries />
            <DegreeCourse />
            <CrashCourse />
            <Stats />
            <Gallery />
            <ArtVideo />
            <FAQ />
            <ContactUs />
            <Footer />
            <BackTopButton/>
        </div>
    </div>
    )
}
