import React from 'react';

export default function About() {
  return (
    <div>
        <section className="section about" id="about" aria-label="about">
        <div className="container">

          <figure className="about-banner ">

            <div className="img-holder img-about " >
              <img src="./images/ceoPhoto.jpg" width="100%" height="370" loading="lazy" alt="about banner"
                className="img-cover" />
            </div>

            <img src="./images/about-shape-1.png" width="360" height="420" loading="lazy" alt=""
              className="shape about-shape-1" />

            <img src="./images/about-shape-2.svg" width="371" height="220" loading="lazy" alt=""
              className="shape about-shape-2" />

            <img src="./images/about-shape-3.png" width="722" height="528" loading="lazy" alt=""
              className="shape about-shape-3" />

          </figure>

          <div className="about-content">

            <p className="section-subtitle">About the Founder</p>

            <h2 className="h2 section-title">
              Over 10 Years in <span className="span">ART Teaching</span> for various Skill courses
            </h2>

            <p>
            The founder Supriya Adhikary had a very clear vision about the academy, to keep up the overall growth of individual students. 
            The goal is to enhance student skills and facilitate them in such a way so that they can actually achieve the success, when they are choosing art as their career. 
            Supriya Adhikary has done lot of studies and research while doing the development of the curriculum and her determination is to continue the same and keep developing new ideas and methodologies to help students focus on their hobbies, their career and also improve their mental health which is a very important thing in this era. 
            She believes by taking admission in this academy one can be successful and confident from inside and outside.
            </p>

            {/*<ul className="about-list">

              <li className="about-item">
                <ion-icon name="checkmark-done-outline" aria-hidden="true"></ion-icon>

                <span className="span">Expert Trainers</span>
              </li>

              <li className="about-item">
                <ion-icon name="checkmark-done-outline" aria-hidden="true"></ion-icon>

                <span className="span">Online Remote Learning</span>
              </li>

              <li className="about-item">
                <ion-icon name="checkmark-done-outline" aria-hidden="true"></ion-icon>

                <span className="span">Lifetime Access</span>
              </li>

            </ul>*/}

            <img src="./images/about-shape-4.svg" width="100" height="100" loading="lazy" alt=""
              className="shape about-shape-4" />

          </div>

        </div>
        </section>
    </div>
  )
}
