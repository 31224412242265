import React, { useState } from 'react';
import axios from "axios";

const config = require('../config.json');

export default function Footer() {
    const [query, setQuery] = useState({name:'', phoneNo:'', email:'', desc:''});
	const [dispMsg, setDispMsg] = useState('');
	const [buttonClicked, setButtonClicked] = useState(false);

    const handleSubmit=async(event)=>{
        event.preventDefault();
        console.log("Query details : ", query);
        setDispMsg('');
		setButtonClicked(true);
		try{
            const {name, email, desc} = query;
			if(name===""||email===""||desc===""){
				setDispMsg("Kindly fill all the details to share your Query/Feedback");
			}else{
				const inputParams={
					name : name,
					//phone : phone,
					email : email,
					desc : desc
				};

			await axios.post(`${config.api.invokeURL}/contact_us/query`, inputParams,{
				headers: {
				//Authorization: access_token,
				'x-api-key':config.api.key
				}})
				.then((response)=>{
					if (response.status===200){
						setDispMsg("Thank you for your Query/feedback! our team will get back to you soon!");
						setQuery({...query, name:'', email:'', desc:''})
                        setButtonClicked(false);
					}
				})
				.catch((error)=>{
					setDispMsg("Error Occured : "+error.message);
					setButtonClicked(false);
				});
			}
			setButtonClicked(false);
		}
		catch(err){
			setDispMsg("Error Occured : "+err.message);
			setButtonClicked(false);
		}
    }

  return (
    <div id="contact">
    <footer className="footer" style={{backgroundImage: `url('./assets/images/footer-bg.png')`}}>

        <div className="footer-top" style={{paddingTop:"40px", paddingBottom:"40px"}}>
        <div className="container grid-list">

            <div className="footer-brand" >
           
           <div style={{display:"flex", justifyContent:"space-around", paddingLeft:"6%", paddingRight:"6%"}}>
               <img src="./images/creativeWorldAcademyLogo.png" width="60px" height="40px" style={{borderRadius:20}} alt="EduWeb logo" />
             <h3 style={{paddingTop:"3%", color:"white", fontFamily:"Satisfy", fontSize:"2.5rem"}}>
                The Creative World Academy
                </h3>   
           </div>
            <p className="footer-brand-text" style={{fontFamily:"Courgette"}}>
            The creative world by Supriya is an institute for people who are passionate towards art. Give your creativity a higher level by exploring our unique methodology and technique.
            </p>

            {/*<div className="wrapper">
                <span className="span">Add:</span>

                <address className="address">70-80 Upper St Norwich NR2</address>
  </div>*/}

            <div className="wrapper " >
                <span className="span p-2"><ion-icon name="call"></ion-icon></span>

                <a  href="tel:+011234567890" className="footer-link" style={{textDecoration:"none", color:"white"}}>+91 - 80744 95902 </a>
            </div>

            <div className="wrapper ">
                <span className="span p-2"><ion-icon name="send"></ion-icon></span>

                <a href="mailto:thecreativeworldbysupriya@gmail.com" className="footer-link" style={{textDecoration:"none", color:"white"}}>thecreativeworldbysupriya@gmail.com</a>
            </div>

            </div>

            <ul className="footer-list" >
  
            <li style={{display:"flex", justifyContent:"center", gap:"12px" }}>
                <div>
                     <ion-icon name="pin" style={{fontSize:"20px"}}></ion-icon>
                </div>
                <h3 style={{paddingBottom:"3%", color:"white"}}><span className="span">
                    
                   Chanda Nagar
                    
                    </span>
                    
                    </h3>
            </li>
            <p>The Creative World, <br />
                    C/o Saraswati Vidya Mandir school, <br />
                    Manjeera pipeline road, <br />
                    Chandanagar, <br />
                    Hyderabad, <br />
                    Telangana – 500050.</p>

            </ul>

            <ul className="footer-list">

            <li style={{display:"flex", justifyContent:"center", gap:"12px" }}>
            <ion-icon name="pin" style={{fontSize:"20px"}}></ion-icon>
                <h3 style={{paddingBottom:"3%", color:"white"}}><span className="span">
                    
                   Ameenpur
                    </span>
                    
                    </h3>
            </li>
            <p>The Creative World, <br />
            Villa – 28, <br />
            Supraja Harmony, <br />
            Ameenpur, Beside Ameenpur Govt school, <br />
            Hyderabad, <br />
            Telangana – 502032.</p>
            </ul>

            {/*<div className="footer-list">
            <p className="footer-list-title">Contact Us</p>
            <p className="footer-list-text">
                Any queries, reach out to us by filling out these details
            </p>
            <form action="" className="newsletter-form">
                <input type="text" name="name" placeholder="Your Name" required className="input-field" 
                value={query.name} onChange={event=>setQuery({...query, name:event.target.value})}/>
                <input type="email" name="email_address" placeholder="Your email" required className="input-field" 
                value={query.email} onChange={event=>setQuery({...query, email:event.target.value})}/>
                <textarea name="query"  rows="4" placeholder="Your query" required className="input-field" 
                value={query.description} onChange={event=>setQuery({...query, description:event.target.value})}/>
                <button type="submit" className="btn has-before" onClick={event=>handleSubmit(event)}>
                <span className="span">Submit</span>
                <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                </button>
            </form>

            <ul className="social-list">
                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                </a>
                </li>

                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                </a>
                </li>

                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                </a>
                </li>

                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                </a>
                </li>

                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-youtube"></ion-icon>
                </a>
                </li>

            </ul>

            </div>*/}

        </div>
        </div>

        <div className="footer-bottom">
        <div className="container">

            <p className="copyright">
            Copyright 2022 All Rights Reserved by <a href="#" className="copyright-link">idntica.com</a>
            </p>

        </div>
        </div>

    </footer>
    </div>
  )
}
