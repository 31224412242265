import React, { useState } from 'react';
import axios from "axios";

const config = require('../config.json');

export default function ContactUs() {
    const [query, setQuery] = useState({name:'', phoneNo:'', email:'', desc:''});
	const [dispMsg, setDispMsg] = useState('');
	const [buttonClicked, setButtonClicked] = useState(false);

    const handleSubmit=async(event)=>{
        event.preventDefault();
        //console.log("Query details : ", query);
        setDispMsg('');
		setButtonClicked(true);
		try{
            const {name, emailID, phoneNo, desc} = query;
			if(name===""||emailID===""||desc===""||phoneNo===""){
				setDispMsg("Kindly fill all the details to share your Query/Feedback");
			}else{
				const inputParams={
					name : name,
					phoneNo : phoneNo,
					emailID : emailID,
					desc : desc
				};
                //console.log("Params : ", inputParams);

			await axios.post(`${config.api.invokeURL}/contact_us/query`, inputParams,{
				headers: {
				//Authorization: access_token,
				'x-api-key':config.api.key
				}})
				.then((response)=>{
					if (response.status===200){
						setDispMsg("Thank you for your Query/feedback! our team will get back to you soon!");
						setQuery({...query, name:'', emailID:'', desc:'', phoneNo:''})
                        setButtonClicked(false);
					}
				})
				.catch((error)=>{
					setDispMsg("Error Occured : "+error.message);
					setButtonClicked(false);
				});
			}
			setButtonClicked(false);
		}
		catch(err){
			setDispMsg("Error Occured : "+err.message);
			setButtonClicked(false);
		}
    }

  return (
    <div id="contact">
        <section className="section about" id="about" aria-label="about">
        <div className="container">
        <figure className="about-banner">

<div className="img-holder" style={{width: 520, height: 370}}>
  <img src="./images/banner/banner_contact_us.jpg" width="100%" height="370" loading="lazy" alt="about banner"
    className="img-cover" />
</div>

<img src="./images/about-shape-3.png" width="722" height="528" loading="lazy" alt=""
  className="shape about-shape-3" />

</figure>

<div className="about-content">
            <p className="h2 section-title">Contact Us</p>
            <p className="section-text">
                Any queries, reach out to us by filling out these details
            </p>
            <form action="" className="newsletter-form">
                <input type="text" name="name" placeholder="Your Name" required className="input-field" 
                value={query.name} onChange={event=>setQuery({...query, name:event.target.value})}/>
                <input type="text" name="phone_number" placeholder="Your Phone Number" required className="input-field" 
                value={query.phoneNo} onChange={event=>setQuery({...query, phoneNo:event.target.value})}/>
                <input type="email" name="email_address" placeholder="Your email" required className="input-field" 
                value={query.emailID} onChange={event=>setQuery({...query, emailID:event.target.value})}/>
                <textarea name="query"  rows="3" placeholder="Your query" required className="input-field" 
                value={query.desc} onChange={event=>setQuery({...query, desc:event.target.value})}/>
                <data className="price">{dispMsg}</data>
                <button type="submit" className="btn has-before" onClick={event=>handleSubmit(event)}>
                Submit
                </button>
            </form>
            <ul className="social-list" style={{display:"flex", justifyContent:"space-between", padding:"20%"}}>
                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                </a>
                </li>

                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                </a>
                </li>

                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                </a>
                </li>

                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                </a>
                </li>

                <li>
                <a href="#" className="social-link">
                    <ion-icon name="logo-youtube"></ion-icon>
                </a>
                </li>

            </ul>
            </div>
            </div>
        </section>
    </div>
  )
}
