import React,{useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
/*import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
  } from 'reactstrap';*/

  import { faqData } from '../sitedata';
  
export default function FAQ() {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };
  
  return (
    <div>
        <section className="section course" id="courses" aria-label="course">
        <div className="container">
          <h2 className="h2 section-title">Frequently Asked Questions</h2>
          <h2 className="section-subtitle">Some of the commonly asked questions are highlighed below, if you still have any queries, please reach out to us.</h2>
         {/*} <Accordion open={open} toggle={toggle}>
            {faqData.map((item,index)=>
        <AccordionItem>
          <AccordionHeader targetId={index}>{item.title}</AccordionHeader>
          <AccordionBody accordionId={index}>
            {item.desc}
          </AccordionBody>
        </AccordionItem>)}
            </Accordion>*/}


<Accordion defaultActiveKey="0" flush>
{faqData.map((item,index)=>
      <Accordion.Item eventKey={index}>
        <Accordion.Header><h3>{item.title}</h3></Accordion.Header>
        <Accordion.Body>
         <h4 style={{fontStyle:"italic", textAlign:"left", paddingLeft:"35px", lineHeight:"35px"}}> {item.desc} </h4>
           </Accordion.Body>
      </Accordion.Item>)}
    </Accordion>
    </div>
    </section>
   
    </div>
  )
}
