import React from 'react';

export default function () {
  return (
    <main>
      <article>
        <section className="section hero has-bg-image" id="home" aria-label="home"
                style={{backgroundImage: `url("./images/hero-bg.svg")`}}>
                <div className="container">
                <div className="hero-content">
                    <h1 className="h1 section-title">
                    The Best place to <span className="span">Enroll</span> for Art's learning
                    </h1>
                    <p className="hero-text">
                    The creative world by Supriya is an institute for people who are passionate towards art. Give your creativity a higher level by exploring our unique methodology and technique. This course is designed for Kids above 4 years and Adults.
                    </p>
                    <a href="#courses" className="btn has-before">
                    <span className="span">Find courses</span>
                    <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
                    </a>
                </div>
                <figure className="hero-banner">
                    <div className="img-holder one" >
                    <img src="./images/hero-banner-1.jpg" width="270" height="300" alt="hero banner" className="img-cover"/>
                    </div>
                    <div className="img-holder two" >
                    <img src="./images/hero-banner-2.jpg" width="240" height="370" alt="hero banner" className="img-cover"/>
                    </div>
                    <img src="./images/hero-shape-1.png" width="380" height="190" alt="" className="shape hero-shape-1"/>
                    <img src="./images/hero-shape-2.png" width="622" height="551" alt="" className="shape hero-shape-2"/>
                </figure>
                </div>
            </section>
      </article>
    </main>
  )
}
