import React from 'react';

export default function AboutUs() {
  return (
    <div>
        <section className="section" id="about" aria-label="about">
        <div className="container">

         {/*} <figure className="about-banner">

            <div className="img-holder" style={{width: 520, height: 370}}>
              <img src="./images/ceoPhoto.jpg" width="100%" height="370" loading="lazy" alt="about banner"
                className="img-cover" />
            </div>

            <img src="./images/about-shape-1.png" width="360" height="420" loading="lazy" alt=""
              className="shape about-shape-1" />

            <img src="./images/about-shape-2.svg" width="371" height="220" loading="lazy" alt=""
              className="shape about-shape-2" />

            <img src="./images/about-shape-3.png" width="722" height="528" loading="lazy" alt=""
              className="shape about-shape-3" />

  </figure>*/}

          <div className="about-content">

          <p className="section-subtitle">About US</p>

            <h2 className="h2 section-title">
              The <span className="span">CREATIVE WORLD</span> Academy
            </h2>

            <p>
            {'        '} The Creative World by Supriya is an academy for drawing and painting. This visual art academy was established in the year 2005. It offers a very stable program and curriculum to its students which benefits them to develop their overall performance and help them to reach the peak of their creativity. The creative world by Supriya has done extensive research while developing the curriculum. The syllabus is very effective to develop students' skills, level wise which actually helps from pre-primary students to adults to explore their talent to the utmost level and become experts. The vast and core level of learning experience makes the student fulfil their passion in a very unique and innovative way. The courses are designed to develop the observation power, increase the patience level, improve handwriting, improve imagination and visualisation power, and exhilarate the confidence level of students. We offer classes for India, USA, Canada and the UK. Many students benefited from this course and pursued their further studies and career towards different streams like, Multimedia, graphic designing, fashion designing, interior decoration, movie making and architecture.
            </p>

            <p >
            The Creative world by Supriya is the only institute in South India which is recognised by West Bengal state academy of Fine Arts, Rabindra Bharati University. Students are able to complete their Diploma, Graduation, Post graduation in Visual art.
            </p>

            {/*<ul className="about-list">

              <li className="about-item">
                <ion-icon name="checkmark-done-outline" aria-hidden="true"></ion-icon>

                <span className="span">Expert Trainers</span>
              </li>

              <li className="about-item">
                <ion-icon name="checkmark-done-outline" aria-hidden="true"></ion-icon>

                <span className="span">Online Remote Learning</span>
              </li>

              <li className="about-item">
                <ion-icon name="checkmark-done-outline" aria-hidden="true"></ion-icon>

                <span className="span">Lifetime Access</span>
              </li>

            </ul>*/}

            <img src="./images/about-shape-4.svg" width="100" height="100" loading="lazy" alt=""
              className="shape about-shape-4" />

          </div>

        </div>
        </section>
    </div>
  )
}
