import React, { useState } from 'react';

import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css'

import HomePage from './pages/HomePage';
import NavBar from './pages/NavBar';
import TopNavBar from './pages/TopNavBar';
import NavbarTest from './pages/NavbarTest';
import Header from './pages/header/Header';


function App() {
  return (
    <div className="App">
    {/*}  <Header />
     */}
            <Header />

      <HomePage />
    </div>
  );
}

export default App;