export const homeAbout = [
    {
      id: 1,
      cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
      title: "Offline Courses",
      desc: "Unlock Your Creativity: Enroll in our Offline Art Courses and manifest the power of learning.",
    },
    {
      id: 1,
      cover: "https://img.icons8.com/ios/80/000000/diploma.png",
      title: "Earn A Certificate",
      desc: "Certify your Artistic Journey Today: Build a strong foundation of your Art and get recognised.",
    },
    {
      id: 1,
      cover: "https://img.icons8.com/ios/80/000000/athlete.png",
      title: "Learn with Expert",
      desc: "Discover the Joy of Drawing and Painting: Learn from our Art Expert in the Creative World Academy!",
    },
  ]
  export const awrapper = [
    {
      cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
      country:"fab fi fi-in icon",
      data: "3,000",
      title: "INDIA",
    },
  
    {
      cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
      country:"fab fi fi-gb icon",
      data: "320",
      title: "U.K",
    },
    {
      cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
      country:"fab fi fi-us icon",
      data: "1,000",
      title: "U.S.A",
    },
    {
      cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
      country:"fab fi fi-ca icon",
      data: "587",
      title: "CANADA",
    },
  ]
  export const coursesCard = [
    {
      id: 1,
      cover: "./images/gallery/basic.jpeg",
      coursesName: "Basic Level",
      levelTitle:"Beginner",
      desc:"A basic art course covers the fundamental principles and techniques of various art forms. The basic course has 3 levels and is desgined to start your art journey. It's a great way to explore your creativity and develop your artistic skills. In a basic art course, you can expect to learn about things like drawing, painting, color theory, composition, perspective, and even some art history. It's a wonderful opportunity to express yourself and discover your artistic style. So, if you're interested in unleashing your inner artist, a basic art course might be just the thing for you!",
      levels:3,
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by John Smith",
          totalTime: "50 lectures (190 hrs)",
        },
      ],
      levelDetails:[
        {level: "Level 1",
        details:[{
          item:"Introduction of lines, connecting dots and shapes."},
          {item:"Concept of colours and their applications."},
          {item:"Holding of pencil and colours in an appropriate way so that handwriting should also improve along with drawing and colouring."},
          {item:"Improve concentration level through art therapy by giving students such tasks."}
        ]},
        {level: "Level 2",
        details:[{
          item:"Draw and colour independently"},
          {item:"Introduction of colour blending with two or more shades."},
          {item:"Basics of landscapes and different shapes and elements."},
         {item:"Knowledge about colour, proportions, shapes and subject of any particular painting."
        }]},
        {level: "Level 3",
        details:[{
          item:"Explore new mediums like pencil shading."},
          {item:"Light and dark concept."},
          {item:"Introduction of different strokes, shading and blending."},
          {item:"Subject or theme based studies."
        }]},
      ]
    },
    {
      id: 2,
      cover: "./images/gallery/inter.jpg",
      coursesName: "Intermediate Level",
      levelTitle:"Intermediate",
      desc:"It's the perfect stepping stone between the basic and advanced levels of art. An intermediate art course builds upon the foundational skills learned in a basic course and helps you further develop your artistic abilities. In an intermediate art course, you can explore more complex techniques, experiment with different mediums, and refine your artistic style. You'll dive deeper into topics such as advanced drawing techniques, shading and lighting, perspective, color mixing, and more. This course will challenge you to push your basic level boundaries.",
      levels:2,
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Ram Gurung",
          totalTime: "30 lectures (125 hrs)",
        },
      ],
      levelDetails:[
        {level: "Jr. Diploma",
        details:[{
          item:"Introduction of different mediums like water colour, pencil .., poster colour, pastel and how to mix mediums together."},
          {item:"Understanding of the main elements of the picture."},
          {item:"Concept of proportion and perspectives."},
          {item:"Students are taught the ability to visualise and put it together on the paper."
        }]},
        {level: "Sr. Diploma",
        details:[{
          item:"Definition and implementation of perspective harmony and rhythm of colour(tint)."},
          {item:"Concept of antique, vanishing point, magnitude, stucco, canvas, contour."},
          {item:"Study about Indian as well as foreign artist biography."},
          {item:"Core level of knowledge about different methodology and tools to create different textures."},
          {item:"Introduction and complete knowledge of water colour."
        }]},
      ]
    },
    {
      id: 3,
      cover: "./images/gallery/advance.jpg",
      coursesName: "Advanced Level",
      levelTitle:"Advanced",
      desc:"An advanced art course is designed for those who have already mastered the basics and are looking to further refine their artistic skills and explore more complex techniques. You'll likely explore more advanced concepts like advanced color theory, advanced composition techniques, experimental art, etc.. It's a fantastic opportunity to push the boundaries of your creativity and take your artistic abilities to the next level. So, if you're ready to challenge yourself and expand your artistic horizons, an advanced art course might be just the thing for you!",
      levels:2,
      courTeacher: [
        {
          dcover: "./images/back.webp",
          name: "by Saroj Nepal",
          totalTime: "50 lectures (50 hrs)",
        },
      ],
      levelDetails:[
        {level: "Graduate Level",
        details:[{
          item:"Complete study of human figure and different parts of human."},
        {item:"Complete study on animal figures and different parts of animals."},
          {item:"Students are expected to create human or animal based senses on paper or canvas."},
          {item:"Making of collage, Immaterial painting(abstract), Fresco, wash, tempera."},
          {item:"Study of different human expressions."},
          {item:"Studies on different artist biographies across the world."},
          {item:"Creation of design, pattern knowledge about fine arts and folk arts."
        }]},
        {level: "P-G. Level",
        details:[{
          item:"Complete knowledge of Portrait using different methods, techniques and mediums."},
          {item:"Making design of cover paper for advertisement of movie."},
          {item:"Methods of art work preservation."},
          {item:"A vast knowledge of different types of art across the globe along with Indian art study."
        }]},
      ]
    }
  ]
  export const crashCourseData = [
    {
      id:1,
      cover: './images/gallery/oilPastel.jpg',
      hoverCover: "./images/courses/online/oilPaintingSample.jpg",
      courseName: "Foundation Courses",
      desc:"A foundation art course introduces students to various artistic techniques, principles, and concepts, fostering creativity and building a strong artistic foundation.",
      duration: "3 Months",
      certificate:true,
      details:[{
        details: "For age group 4 years and adults",
      },{
        details: "This course helps to get knowledge of basic drawing and painting",
      },{
        details: "Starting from pencil sketching to the knowledge of perspective drawings with different mediums like pencil, pastels, colour pencil, charcoal and watercolor.",
      }]
    },
    {
      id:2,
      cover: "./images/gallery/oil.jpg",
      hoverCover: "./images/courses/online/oilPaintingSample.jpg",
      courseName: "Oil Painting",
      desc:"Oil painting is a traditional artistic technique that uses pigments mixed with oil as a medium to create vibrant and long-lasting artworks.",
      duration: "3 Months",
      certificate:true,
      details:[{
        details: "For age group 10 years and adults",
      },{
        details: "Different techniques of oil paint.",
      },{
        details: "Knowledge on different tools to be used in oil painting.",
      },{
        details: "How to preserve oil painting for years.",
      }]
    },
    {
      id:3,
      cover: "./images/gallery/pencil.jpg",
      hoverCover: "./images/courses/online/oilPaintingSample.jpg",
      courseName: "Pencil Shading",
      desc:"Pencil shading is a technique that uses varying degrees of pressure and graphite pencils to create realistic and dimensional drawings with shading and tonal values.",
      duration: "2 Months",
      certificate:true,
      details:[{
        details: "For all age groups.",
      },{
        details: "Pencil sketching with the knowledge of pencils and their application.",
      },{
        details: "Charcoal shading.",
      },{
        details: "Pastels work.",
      }]
    },
    {
      id:4,
      cover: "./images/gallery/watercolor.jpeg",
      hoverCover: "./images/courses/online/o4.1.png",
      courseName: "Water Colour",
      desc:"Watercolor is a versatile painting technique that uses water-soluble pigments to create translucent and vibrant artworks with fluid and transparent effects.",
      duration: "3 Months",
      certificate:true,
      details:[{
        details: "For age group 10 years and adults.",
      },{
        details: "Different types of watercolor techniques and methods.",
      },{
        details: "Knowledge of application on different papers.",
      }]
    },
    {
      id:5,
      cover: "./images/gallery/acrylic.jpg",
      hoverCover: "./images/courses/online/o5.1.png",
      courseName: "Acrylic Painting",
      desc:"Acrylic painting is a versatile and fast-drying technique that uses acrylic paints to create vibrant and textured artworks on various surfaces.",
      duration: "3 Months",
      certificate:true,
      details:[{
        details: "For age groups 10 years and above.",
      },{
        details: "Different techniques of acrylic painting.",
      },{
        details: "Acrylic with mix media.",
      }]  },
    {
      id:6,
      cover: "./images/gallery/fabric.jpg",
      hoverCover: "./images/courses/online/o6.1.png",
      courseName: "Fabric Painting",
      desc:"Fabric painting is a creative technique that uses specialized fabric paints to add color, patterns, and designs to textiles, allowing for personalized and unique creations.",
      duration: "2 Months",
      certificate:true,
      details:[{
        details: "For age group 10 years and adults.",
      },{
        details: "Different types of fabric painting.",
      },{
        details: "Printing on cloth.",
      },{
        details: "Spray technique.",
      },{
        details: "Application on different types of clothes for eg., on silk, cotton, organza polyester etc.,",
      }]  },
    {
      id:7,
      cover: "./images/gallery/glass.jpg",
      hoverCover: "./images/courses/online/o7.1.png",
      courseName: "Glass Painting",
      desc:"Glass painting is a decorative technique that involves applying specialized paints and techniques to create colorful and intricate designs on glass surfaces, adding a touch of elegance and beauty.",
      duration: "2 Months",
      certificate:true,
      details:[{
        details: "For age group 10 years and adults.",
      },{
        details: "Different techniques with glass colour.",
      },{
        details: "Mixed media on glass surface.",
      }]  },
    {
      id:8,
      cover: "./images/gallery/mural.jpg",
      hoverCover: "./images/courses/online/o8.1.png",
      courseName: "Mural Art",
      desc:"Mural art is a large-scale form of artistic expression that transforms walls and public spaces into captivating and visually immersive works of art, often conveying powerful messages or enhancing the aesthetic appeal of the environment.",
      duration: "3 Months",
      certificate:true,
      details:[{
        details: "For age group 10 years and adults.",
      },{
        details: "Different types of cement work on different surfaces.",
      },{
        details: "Knife technique.",
      },{
        details: "Usage of different tools.",
      },{
        details: "Knowledge of colour application and medium.",
      }]  },
    {
      id:9,
      cover: "./images/gallery/mandal.jpg",
      hoverCover: "./images/courses/online/o9.1.png",
      courseName: "Mandal Art",
      desc:"Mandal art is a spiritual and meditative practice that involves creating intricate and symmetrical designs, typically in circular form, to promote mindfulness, balance, and inner harmony.",
      duration: "2 Months",
      certificate:true,
      details:[{
        details: "For all age groups.",
      },{
        details: "Introduction to both traditional and modern mandala art.",
      },{
        details: "Usage of various tools for creating design.",
      },{
        details: "Knowledge of different colour shades and their application.",
      }]  },
    {
      id:10,
      cover: "./images/gallery/potpaint.jpg",
      hoverCover: "./images/courses/online/o10.1.png",
      courseName: "Pot Painting",
      desc:"Pot painting is a creative technique that involves decorating and adding vibrant colors to pots and containers, transforming them into eye-catching and personalized decorative pieces for plants or home decor.",
      duration: "2 Months",
      certificate:true,
      details:[{
        details: "For all age groups.",
      },{
        details: "Knowledge of creating texture on clay pots.",
      },{
        details: "Usage of mixed medium on clay pot.",
      }]  },
    {
      id:11,
      cover: "./images/gallery/knife.jpg",
      hoverCover: "./images/courses/online/o11.1.png",
      courseName: "Knife Painting",
      desc:"Knife painting is a dynamic and textured technique that utilizes palette knives to apply thick layers of paint, creating bold and expressive artworks with distinct texture and visual impact.",
      duration: "2 Months",
      certificate:true,
      details:[{
        details: "For age group 10 years and adults.",
      },{
        details: "Application of colour.",
      },{
        details: "How to make the base.",
      },{
        details: "How to apply different techniques effectively.",
      }]  },
    {
      id:12,
      cover: "./images/gallery/charcoal.jpeg",
      hoverCover: "./images/courses/online/o12.1.png",
      courseName: "Charcoal Painting",
      desc:"Charcoal painting is a versatile and expressive technique that utilizes charcoal sticks or pencils to create rich, dramatic, and nuanced artworks with a wide range of tones and textures.",
      duration: "2 Months",
      certificate:true,
      details:[{
        details: "For all age groups.",
      },{
        details: "How to create charcoal painting from scratch.",
      },{
        details: "Different types of materials required.",
      },{
        details: "Methods of preserving charcoal painting.",
      }]  },
  ]

  export const videoData=[
    {
      id:1,
      sourceURL:'./videos/arcylic-painting1.mp4'
    },{
      id:2,
      sourceURL:'./videos/arcylic-painting2.mp4'
    },{
      id:3,
      sourceURL:'./videos/arcylic-painting3.mp4'
    }
  ]

  export const galleryData=[
    {
      id:1,
      sourceURL:'./images/gallery/gallery1.jpeg',
      caption:'Test1',
      desc:'Desc1'
    },{
      id:2,
      sourceURL:'./images/gallery/gallery2.jpeg',
      caption:'Test2',
      desc:'Desc2'
    },{
      id:3,
      sourceURL:'./images/gallery/gallery3.jpeg',
      caption:'Test3',
      desc:'Desc3'
    }
  ]

  export const team = [
    {
      cover: "./images/team/t1.webp",
      name: "Ph.D Adrian Molises",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
    {
      cover: "./images/team/t2.webp",
      name: "Ph.D Arthur MaGregor",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
    {
      cover: "./images/team/t3.webp",
      name: "Ph.D Anna Hanzen",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
    {
      cover: "./images/team/t4.webp",
      name: "Ph.D Brian Wooden",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
    {
      cover: "./images/team/t5.webp",
      name: "Ph.D Adrian Molises",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
    {
      cover: "./images/team/t6.webp",
      name: "Ph.D Arthur MaGregor",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
    {
      cover: "./images/team/t7.webp",
      name: "Ph.D Anna Hanzen",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
    {
      cover: "./images/team/t8.webp",
      name: "Ph.D Brian Wooden",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
  ]
  export const price = [
    {
      name: "BASIC PLAN",
      price: "49K",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
      name: "BEGINNER PLAN",
      price: "79K",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
      name: "PREMIUM PLAN",
      price: "109k",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
      name: "ULTIMATE PLAN",
      price: "149K",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
  ]
  export const faqData = [
    {
      id:1,
      title: "How to enroll for classes?",
      desc: "Browse through the course catalogue and please call us and enquire if you can join the class. After that you can create an account to enroll for the course and pay the required amount through any mode – Cash, UPI, Bank Transfer, etc.,",
    },
    {
      id:2,
      title: "What is the minimum age for enrolling in painting classes?",
      desc: "The minimum age for enrolling is 4 years. Some painting classes are available for both kids and adults like Pencil Sketching, Oil Painting, Acrylic painting, Watercolour, Charcoal, Finger painting etc. For full details please visit the Courses page and select your result.",
    },
    {
      id:3,
      title: "Are online courses available?",
      desc: "Yes, online classes are available where you will be taught in a group over a Google meet call. The courses can be learnt in offline class as well.",
    },
    {
      id:4,
      title: "Does Mrs. Supriya teach all the courses?",
      desc: "Yes. All courses are taught by Mrs. Supriya, from the last 18 years. We never had any assistant teachers so that every student gets the best attention and support.",
    },
    {
      id:5,
      title: "Is the course fee transferable to another course?",
      desc: "Unfortunately, no. You cannot transfer your course fee to another course. However you may finish the ongoing course and enroll for another course or simultaneously enroll in as many courses as you want to.",
    },
    {
      id:6,
      title: "Will I get a refund in case I wish to discontinue from the course?",
      desc: "Once you get enrolled as a student, we will not be able to refund, even if you weren’t able to attend a single class. We suggest that you take in account your schedule and decide for yourself if you would be able to finish the course.",
    },
    {
      id:7,
      title: "Any unanswered questions?",
      desc: "Please reach out to us via phone, whatsapp, and contact us - query section. Thank you!",
    },
  ]
  export const blog = [
    {
      id: 1,
      type: "admin",
      date: "JAN. 18, 2021",
      com: "3 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b1.webp",
    },
    {
      id: 2,
      type: "admin",
      date: "API. 25, 2022",
      com: "5 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b2.webp",
    },
    {
      id: 3,
      type: "user",
      date: "MAY. 15, 2022",
      com: "10 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b3.webp",
    },
    {
      id: 4,
      type: "admin",
      date: "JAN. 02, 2022",
      com: "3 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b4.webp",
    },
    {
      id: 5,
      type: "admin",
      date: "DEC. 14, 2022",
      com: "3 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b5.webp",
    },
    {
      id: 6,
      type: "user",
      date: "JAN. 18, 2021",
      com: "12 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b6.webp",
    },
  ]
  export const testimonal = [
    {
      id: 1,
      name: "ROGER SCOTT",
      post: "MARKETING MANAGER",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
      cover: "./images/testo/t1.webp",
    },
    {
      id: 2,
      name: "ROGER SCOTT",
      post: "MARKETING MANAGER",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
      cover: "./images/testo/t2.webp",
    },
    {
      id: 3,
      name: "ROGER SCOTT",
      post: "MARKETING MANAGER",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
      cover: "./images/testo/t3.webp",
    },
  ]
  